<template>
    <nut-popup v-model="visible" round position="bottom" @close="hide">
        <div class="coupon-window">
            <div class="coupon-header">
                <div class="coupon-title">可用优惠券</div>
                <div class="close">
                    <img
                            :src="require('@STATIC/image/icons/closeFill-gray.png')"
                            @click.stop="hide"
                    />
                </div>
            </div>

            <div class="coupon-list">
                <template v-for="(item,index) in couponList">
                    <coupon-with-goods v-if="item.type == 1" style="margin-bottom:10px" :record="item"
                                       :key="index+key" :couponId="couponId" @chooseCouponId="chooseCouponId"></coupon-with-goods>
                </template>
            </div>

        </div>
    </nut-popup>
</template>

<script>
  import CouponWithGoods from '@/components/system-coupon/coupon-with-goods'

  export default {
    name: 'couponChoose',
    components: {CouponWithGoods},
    props: {
      couponList: {
        type: Array,
        default: () => {
          return []
        }
      },
      couponId: {
        type: [String, Number],
        default: 0
      }
    },
    data () {
      return {
        visible: false,
        key:Math.random()
      }
    },
    watch: {
      couponId(newV){
        this.key = Math.random()
      }
    },
    methods: {
      show () {
        this.visible = true
      },
      hide () {
        this.visible = false
      },
      chooseCouponId(id){
        this.$emit('chooseCouponId',id)
      }
    }
  }
</script>

<style scoped lang="scss">
    .coupon-window {
        height: 70vh;
        width: 100vw;
        background: #fff;
        display: flex;
        flex-direction: column;

        .coupon-header {
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .coupon-title {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
            }

            .close {
                position: absolute;
                width: 20px;
                height: 20px;
                top: 5px;
                right: 15px;

                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .coupon-list {
            flex: 1;
            padding: 10px 15px;
            overflow-y: scroll;
        }
    }
</style>