<template>
    <div class="coupon-container">
        <div class="disabled" v-if="record.status == 0"></div>
        <div class="coupon-left" @click="choose">
            <div class="coupon-type">商品代金券</div>
            <div class="goods-price">
                <div class="price-info">
                    <div class="price">
                        <span>¥</span>{{goods.market_price}}
                    </div>
                    <div class="desc">
                        <template v-if="record.use_limit == 0">使用不设限制</template>
                        <template v-if="record.use_limit == 1">
                            <template v-if="record.limit_type == 1">消费满{{record.limit_money}}使用</template>
                            <template v-if="record.limit_type == 2">累计满{{record.limit_money}}使用</template>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="coupon-right" @click="choose">
            <div class="coupon-name">{{record.name}}</div>
            <div class="coupon-desc">{{record.desc}}</div>
            <div class="coupon-duration">
                有效期至：{{record.expire_time_format}}
            </div>
            <div class="choose">
                <rsck-radio :checked="checked"></rsck-radio>
            </div>
        </div>
    </div>
</template>

<script>
  import $community from '@A/community/index.js'
  import RsckRadio from '@/components/common/rsck-radio'

  export default {
    name: 'coupon-with-goods',
    components: {RsckRadio},
    props: {
      record: {
        type: Object,
        default: () => {
          return {}
        }
      },
      couponId: {
        type: [String, Number],
        default: 0
      }
    },
    data () {
      return {
        goods: {},
        checked: false
      }
    },
    mounted () {
      this.getGoodsInfo()
      if (this.$props.record.coupon_id == this.$props.couponId) {
        this.checked = true
      }
    },
    methods: {
      getGoodsInfo () {
        $community.goods.getInfo({id: this.$props.record.type_value}).then(response => {
          response = response.data

          this.goods = {
            id: response.id,
            title: response.title,
            market_price: response.market_price,
          }
          console.log('this.goods', this.goods)
        })
      },
      choose(){
        this.checked = !this.checked
        console.log('this.checked',this.checked)
        this.$forceUpdate()
        if(this.checked){
          this.$emit('chooseCouponId',this.$props.record.coupon_id)
        }else{
          this.$emit('chooseCouponId',0)
        }
      }
    }
  }
</script>

<style scoped lang="scss">
    .coupon-container {
        display: flex;
        background: #fff;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
        width: 100%;
        position: relative;

        .disabled {
            background: rgba(255, 255, 255, 0.8);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }

        .coupon-left {
            position: relative;
            background: rgba(228, 0, 0, 0.6);
            width: 30%;
            height: 80px;
            color: white;
            align-items: center;
            display: flex;

            &::after {
                content: ' ';
                height: 100%;
                position: absolute;
                border-left: 6px dotted white;
                top: 0;
                right: -3px;
            }

            .coupon-type {
                position: absolute;
                left: 0;
                top: 5px;
                font-size: 10px;
                color: #fff;
                padding: 3px 10px 3px 5px;
                background: rgba(244, 106, 23, 0.36);
                border-radius: 0 20px 20px 0;
            }

            .goods-price {
                flex: 1;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;

                .price-info {
                    padding-top: 15px;
                    flex: 1;
                    display: flex;
                    flex-direction: column;

                    .price {
                        flex: 1;
                        height: 30px;
                        font-size: 28px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        span {
                            font-size: 14px;
                        }
                    }

                    .desc {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 10px;
                    }

                }

            }
        }

        .coupon-right {
            flex: 1;
            display: flex;
            flex-direction: column;
            padding: 10px 10px;
            position: relative;

            .coupon-name {
                flex: 1;
                height: 30px;
                font-size: 14px;
                color: #303030;
                font-weight: 500;
            }

            .coupon-desc {
                flex: 1;
                font-size: 11px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #979797;
            }

            .coupon-duration {
                font-size: 11px;
                color: #303030;
            }

            .choose {
                width: 50px;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }

    }
</style>
