<template>
  <div class="goodsList">
    <div class="goodsList_header">
      <img :src="require('@STATIC/image/icons/shop.png')" />
      <span>{{ storeName }}</span>
    </div>
    <div class="goodsList_content" v-for="(item, index) in list" :key="index">
      <goodsV3
        v-if="index < sliceIndex"
        class="box-m-t-14"
        :img_url="item.cover"
        :description="item.title"
        :rule="item.spec_title"
        :text="item.deduct_text"
        :params="item"
      >
        <div class="numberbox">
          <stepperV1
            :default-value="item.number"
            :min="1"
            :max="item.cart_id > 0 ? 999 : 0"
            @onChange="onChange"
            :disabled="typeof item.is_coupon_goods == 'undefined'"
          ></stepperV1>
        </div>
        <div class="foot">
          <span>总价:￥{{ item.total_product_price }}</span>
          <span>需付款&nbsp￥{{ item.total_market_price }}</span>
        </div>
      </goodsV3>
      <div v-if="index < sliceIndex" class="split"></div>
    </div>
  </div>
</template>

<script>
import goodsV3 from "@T/community/goods/goodsV3";
import stepperV1 from "@T/community/stepper/stepperV1.vue";
export default {
  name: "goodsListV2",
  components: {
    goodsV3,
    stepperV1,
  },
  props: {
    storeName: {
      type: String,
      default: "梦回楼兰",
    },
    goodsList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      isMore: true,
      list: [],
    };
  },
  computed: {
    sliceIndex() {
      return this.list.length;
    },
  },
  watch: {
    goodsList() {
      this.list = this.$props.goodsList;
    },
  },
  mounted() {
    this.list = this.$props.goodsList;
  },
  methods: {
    onChange() {
      this.$utils.throttle(
        "onChange",
        () => {
          this.$emit("onChange", this.list);
        },
        500,
        false
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@include b(goodsList) {
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  @include e(header) {
    display: flex;
    align-items: center;
    & > img {
      width: 19px;
      height: 19px;
    }
    & > span {
      margin-left: 2px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
  }
  @include e(content) {
    &:not(:last-of-type) > .split {
      width: 100%;
      height: 0px;
      border-bottom: 1px solid #ebebeb;
    }
  }
}
@include b(numberbox) {
  margin-top: 10px;
  height: 16px;
  display: flex;
  justify-content: flex-end;
}
@include b(foot) {
  margin-top: 15px;
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > span {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #f46a17;
    &:first-of-type {
      margin-right: 10px;
      color: #a2a2a2;
      text-decoration: line-through;
    }
  }
}
</style>
