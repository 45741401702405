<template>
    <div class="address">
        <div class="address_head">
            <span>{{ info.real_name || '用户昵称' }}</span>
            <span>{{ info.mobile }}</span>
        </div>
        <div class="address_content">
            <div v-if="info.address_id > 0">
                {{ info.province }} {{ info.city }} {{ info.town }} {{ info.address }}
            </div>
            <div v-else class="address_content-none">请选择您的收货地址</div>
            <button @click="onClick" v-if="!hideBtn">修改地址</button>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'addressV1',
    props: {
      info: {
        type: Object,
        default () {
          return {
            address_id: 0, //地址id
            province: '', //省份
            city: '', //城市
            town: '', //城镇
            address: '', //详细地址
            real_name: '', //真是姓名
            mobile: '', //手机号
          }
        },
      },
      hideBtn: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {}
    },

    mounted () {},

    methods: {
      onClick () {
        this.$emit('updataAddress')
      },
    },
  }
</script>

<style lang="scss" scoped>
    @include b(address) {
        width: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 16px 15px;
        background: #ffffff;
        @include e(head) {
            display: flex;
            align-items: flex-end;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #a2a2a2;
            & > span:first-of-type {
                margin-right: 9px;
                font-size: 14px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
            }
        }
        @include e(content) {
            margin-top: 8px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            @include m(none) {
                font-size: 12px !important;
            }

            & > div {
                width: 240px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #a2a2a2;
            }
            & > button {
                width: 82px;
                height: 30px;
                border-radius: 18px;
                border: 1px solid #d1d1d1;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
</style>
