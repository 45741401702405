<template>
    <nut-popup v-model="show" @close="onClose">
        <div class="setUserInfoV1">
            <div class="setUserInfoV1_head">
                <span>编辑提货人信息</span>
                <img
                        :src="require('@STATIC/image/icons/closeFill-gray.png')"
                        @click.stop="onClose"
                />
            </div>
            <formV1
                    ref="uform"
                    style="height: auto"
                    :model="params"
                    :errorType="['message']"
            >
                <formItemV1 prop="real_name">
                    <div class="setUserInfoV1_content">
                        <span>提货人：</span>
                        <input
                                type="text"
                                placeholder="请输入提货人名称"
                                v-model="params.real_name"
                        />
                    </div>
                </formItemV1>
                <formItemV1 prop="real_mobile">
                    <div class="setUserInfoV1_content">
                        <span>手机号：</span>
                        <input
                                type="text"
                                placeholder="请输入提货人手机号"
                                v-model="params.real_mobile"
                        />
                    </div>
                </formItemV1>
            </formV1>
            <button class="setUserInfoV1_foot" @click.stop="onSubmit">保存</button>
        </div>
    </nut-popup>
</template>

<script>
  import formV1 from '@C/form/formV1.vue'
  import formItemV1 from '@C/formItem/formItemV1.vue'

  export default {
    name: 'setUserInfoV1',
    components: {
      formV1,
      formItemV1,
    },
    props: {
      isShow: {
        type: Boolean,
        default: false,
      },
      real_name: {
        type: String,
        default: '',
      },
      real_mobile: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        show: false,
        params: {
          real_name: '',
          real_mobile: '',
        },
        rules: {
          real_name: [
            {
              type: 'string',
              required: true,
              message: '请输入您的联系人姓名',
              trigger: ['blur', 'change'],
            },
          ],
          real_mobile: [
            {
              required: true,
              type: 'string',
              message: '请输入您的联系方式',
              trigger: ['blur', 'change'],
            },
            {
              len: 11,
              message: '请输入11位手机号码',
              trigger: ['blur', 'change'],
            },
            {
              // 自定义验证函数，见上说明
              validator: (rule, value, callback) => {
                // 上面有说，返回true表示校验通过，返回false表示不通过
                return this.$utils.rule.mobile(value)
              },
              message: '手机号码不正确',
              // 触发器可以同时用blur和change
              trigger: ['change', 'blur'],
            },
          ],
        },
      }
    },

    mounted () {
      this.params.real_name = JSON.parse(JSON.stringify(this.$props.real_name))
      this.params.real_mobile = JSON.parse(JSON.stringify(this.$props.real_mobile))
      this.$refs.uform.setRules(this.rules)
    },
    watch: {
      isShow (newV) {
        this.show = newV
      },
    },

    methods: {
      onClose () {
        this.show = false
        this.$emit('close', false)
      },
      onSubmit () {
        this.$refs.uform.validate((vaild) => {
          console.log('vaild', vaild)
          if (vaild) {
            console.log(this.params)
            this.$emit('onConfirm', this.params)
            this.onClose()
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
    @include b(setUserInfoV1) {
        width: 335px;
        background: #ffffff;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 20px;
        box-sizing: border-box;
        padding-bottom: calc(20px + constant(safe-area-inset-bottom));
        padding-bottom: calc(20px + env(safe-area-inset-bottom));
        @include e(head) {
            margin-bottom: 18px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            & > img {
                width: 20px;
                height: 20px;
            }
        }
        @include e(content) {
            height: 44px;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            border-bottom: 1px solid #ebebeb;
            & > input {
                display: flex;
                align-items: center;
                outline: 0;
                border: 0;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                &::placeholder {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666;
                }
            }
        }
        @include e(foot) {
            margin-top: 20px;
            width: 285px;
            height: 44px;
            background: #ffece1;
            border-radius: 3px;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #f46a17;
            &:active {
                opacity: 0.5;
            }
        }
    }
</style>
