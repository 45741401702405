<template>
    <div id="orderConfrim">
        <!--<div class="l-boxs box-p-10"></div>-->
        <div class="box-m-t-10" v-if="choose_address == 1">
            <!-- 快递 -->
            <addressV1
                    v-if="order_info.express_type == 2"
                    :info="express.express"
                    @updataAddress="updataAddress"
            ></addressV1>
            <!-- 自提 -->
            <addressV2
                    v-if="order_info.express_type == 1"
                    :info="express.site_point"
                    @updataSite_person="setUser = true"
                    @updataSite_point="updataSite_point"
            ></addressV2>
        </div>
        <div class="l-boxs box-m-t-10">
            <goodsListV2 :goodsList="goods" @onChange="onGoodsChange"></goodsListV2>
        </div>

        <template v-if="couponGoods.length > 0">
            <div class="l-boxs box-m-t-10">
                <addressV2
                        :info="couponSitePoint"
                        @updataSite_person="setCouponUser = true"
                        @updataSite_point="viewSitePoint"
                ></addressV2>
            </div>

            <div class="box-m-t-10">
                <goodsListV2 :goodsList="couponGoods"></goodsListV2>
            </div>
        </template>


        <div class="card box-m-t-10 card-p20">
          <template v-if="order_info.no_send_warning.length > 0">
            <cellV2
                    class="cell"
                    :type="6"
                    leftText="说明"
                    :rightText="order_info.no_send_warning"
            ></cellV2>
          </template>
          <template v-else>
            <cellV2
                    class="cell"
                    :type="6"
                    leftText="配送方式"
                    :rightText="order_info.express_type == 2 ? '快递配送' : '自提点取货'"
            ></cellV2>
            <cellV2
                    class="cell"
                    :type="6"
                    leftText="商品金额"
                    :rightText="'¥' + order_info.goods_amount"
            ></cellV2>

            <cellV2
                    class="cell"
                    :type="6"
                    leftText="可用优惠券"
                    rightText=""
                    v-if="showCouponInfo &&  couponList.length > 0"
            >
                <div class="coupon-text-container">
                    <div class="coupon-text" @click="chooseCoupon">{{couponText}}</div>
                </div>
            </cellV2>
            <cellV2
                    v-if="isHasDiscount"
                    class="cell"
                    :type="7"
                    leftText="人生券抵扣"
                    :rightText="
          can_use_renshengquan != 0 ? '￥' + can_use_renshengquan : '暂无可用'
        "
                    :rightIcon="require('@/static/image/icons/arrowRight.png')"
                    @onRight="hasRSQInfo ? (useRSQ = true) : (useRSQ = false)"
            ></cellV2>
            <cellV2
                    class="cell"
                    :type="6"
                    leftText="运费"
                    :rightText="'¥'+order_info.shipping_fee"
            ></cellV2>
            <cellV2 class="cell" :type="9" leftText="留言">
                <nut-textinput
                        class="remark"
                        placeholder="请输入您的备注"
                        :clear-btn="false"
                        :value="remark"
                        @input="onRemark"
                />
            </cellV2>
          </template>
        </div>

      <div class="choose-payment card box-m-t-10 card-p20" v-if="order_info.no_send_warning.length == 0">
          <div class="payment-title">
            选择您的支付方式
          </div>
          <div class="payment-type">
            <div class="payment-type-item" style="margin-bottom: 10px">
              <checkedV1 :value="pay_type == 'wechat'" @change="chooseWechat">
                <span class="payment-type-item-span">微信支付</span>
              </checkedV1>
            </div>
            <div class="payment-type-item">
              <checkedV1 :value="pay_type == 'money'" @change="chooseMoney">
                <span class="payment-type-item-span">余额支付</span>
              </checkedV1>
            </div>
            <div class="payment-type-text" v-if="pay_type == 'money'">
              可用余额：¥ {{userMoney}}
            </div>
          </div>
      </div>

        <div class="orderConfrim_placeholder"></div>
        <div class="orderConfrim_foot" v-if="order_info.no_send_warning.length == 0">
            <footbarV2
                    :buyRSQ="isBuyRSQ"
                    :order_info="order_info"
                    :info="isBuyRSQ ? save_info : real_save_info"
                    @showDetail="showDiscountDetail = true"
                    @goBuy="goBuy"
            ></footbarV2>
        </div>
        <discountV1
                :show="showDiscountDetail"
                :info="isBuyRSQ ? save_info : real_save_info"
                @onClose="showDiscountDetail = false"
        ></discountV1>
        <setUserInfoV1
                :isShow="setUser"
                @close="setUser = false"
                :real_name="real_name"
                :real_mobile="mobile"
                @onConfirm="setUserInfo"
        ></setUserInfoV1>

        <setUserInfoV1
                :isShow="setCouponUser"
                @close="setCouponUser = false"
                :real_name="couponSitePoint.real_name"
                :real_mobile="couponSitePoint.mobile"
                @onConfirm="setCouponUserInfo"
        ></setUserInfoV1>

        <useRSQV1
                :isShow="useRSQ"
                :isActive="isBuyRSQ"
                :renshengquan_info="renshengquan_info"
                @onClose="useRSQ = false"
                @confirm="onUseRSQ"
        ></useRSQV1>

        <coupon-choose ref="couponChoose" :couponList="couponList" :coupon-id="useCouponId"
                       @chooseCouponId="chooseCouponId"></coupon-choose>

      <nut-popup :style="{padding:'50px 0 0 0',background:'none'}" v-model="showUpSuccess" closeable :round="true" @close="goToOrderList">
        <img style="width:300px" src="https://api.mengjingloulan.com/storage/v3/icons/tuan-up-success.png"  @click="closeUpSuccess">
      </nut-popup>
    </div>


</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import addressV2 from '@T/community/address/addressV2'
  import addressV1 from '@T/community/address/addressV1'
  import cellV2 from '@T/community/cell/cellV2.vue'
  import goodsListV2 from '@T/community/goodsList/goodsListV2.vue'
  import footbarV2 from '@T/community/footbar/footbarV2.vue'
  import discountV1 from '@T/community/popup/discountV1.vue'
  import setUserInfoV1 from '@T/community/popup/setUserInfoV1'
  import useRSQV1 from '@T/community/popup/useRSQV1'
  import couponChoose from '@T/community/popup/couponChoose'
  import checkedV1 from "@T/community/checked/checkedV1"

  import $community from '@A/community/index.js'


  export default {
    name: 'ordreConfrim',
    needVuex: true,
    configVuex: ['community_order'],
    components: {
      cellV2,
      addressV1,
      addressV2,
      goodsListV2,
      footbarV2,
      discountV1,
      setUserInfoV1,
      useRSQV1,
      couponChoose,
      checkedV1
    },

    data () {
      return {
        showDiscountDetail: false, //展示优惠信息
        setUser: false, //编辑用户信息
        useRSQ: false, //展开使用人生券弹窗
        payInfo: null, //支付订单参数
        paying: false, //正在支付中
        setCouponUser: false,
        goods_plan:0,
        pay_type:'wechat',
        userMoney:0.00,
        showUpSuccess:false
      }
    },
    beforeCreate () {
      // this.$platform.wxsdk.initConfig();
    },
    created () {
      document.title = '确认订单'
      this.init(this.$route.query)
      if(typeof this.$route.query.goods_plan != 'undefined'){
        this.goods_plan = this.$route.query.goods_plan
      }
    },
    watch: {
      useCouponId (newV, oldV) {
        if (newV == 0) {
          //要从商品列表中删除这个优惠券对应的商品
          this.removeCouponGoods()
        } else {
          //要在商品列表中增加这个优惠券对应的商品
          this.addGood(newV)
        }
      },
    },
    computed: {
      ...mapState('community_order', [
        'isBuyRSQ', //是否同时购买人生券默认false
        'cart_ids', //购物车id
        'type', //1 自提 2快递 默认自提
        'remark', //备注
        'goods_id', //商品id
        'specs', //规格
        'number', //购买数量
        'goods',
        'order_info',
        'save_info',
        'real_save_info',
        'renshengquan_info',
        'express',
        'couponList',
        'useCouponId',
        'couponSitePoint',
        'couponGoods',
        'choose_address'
      ]),
      hasRSQInfo () {
        if (this.renshengquan_info.length == 0) {
          return false
        }
        return true
      },
      showCouponInfo () {
        return this.couponList.length > 0
      },
      //商品的金额
      total_market_price () {
        if (this.isBuyRSQ) {
          return this.save_info.total_market_price
        } else {
          return this.real_save_info.total_market_price
        }
      },
      can_use_renshengquan () {
        if (this.isBuyRSQ) {
          return this.save_info.can_use_renshengquan
        } else {
          return this.real_save_info.can_use_renshengquan
        }
      },
      real_name () {
        if (this.order_info.express_type == 2) {
          //快递
          return this.express.express.real_name
        }
        if (this.order_info.express_type == 1) {
          //自提
          return this.express.site_point.real_name
        }
        return ''
      },
      mobile () {
        if (this.order_info.express_type == 2) {
          //快递
          return this.express.express.mobile
        }
        if (this.order_info.express_type == 1) {
          //自提
          return this.express.site_point.mobile
        }
        return ''
      },
      isHasDiscount () {
        //商品是否有可以优惠的人生券
        let isHas = this.goods.find((val) => val.deduct_text != '')
        if (isHas) {
          //商品有折扣
          return true
        } else {
          //商品没有折扣
          return false
        }
      },
      couponText () {
        let text
        if (this.useCouponId > 0) {
          this.couponList.forEach((item) => {
            if (item.coupon_id == this.useCouponId) {
              text = item.name
            }
          })
        } else {
          text = '您有' + this.couponList.length + '个优惠券可以使用'
        }
        this.$forceUpdate()
        return text
      },
    },
    methods: {
      ...
        mapActions('community_order', [
          'init',
          'previewSingleGoods',
          'previewCartGoods',
          'changeGoodsNum',
        ]),
      //设置用户信息
      setUserInfo (params) {
        if (this.order_info.express_type == 2) {
          //快递
          this.$vuex.community_order(
            'express.express.real_name',
            params.real_name
          )
          this.$vuex.community_order(
            'express.express.real_mobile',
            params.real_mobile
          )
        }
        if (this.order_info.express_type == 1) {
          //自提
          this.$vuex.community_order(
            'express.site_point.real_name',
            params.real_name
          )
          this.$vuex.community_order(
            'express.site_point.mobile',
            params.real_mobile
          )
        }
      }
      ,
      setCouponUserInfo (params) {

        this.$vuex.community_order(
          'couponSitePoint.real_name',
          params.real_name
        )
        this.$vuex.community_order(
          'couponSitePoint.mobile',
          params.real_mobile
        )
      },
      //是否使用人生券
      onUseRSQ (e) {
        console.log(e, this.paying)
        this.$vuex.community_order('isBuyRSQ', e)
        // if (this.goods_id > 0) {
        //   this.previewSingleGoods();
        // } else {
        //   this.previewCartGoods();
        // }
        if (this.paying) {
          if (this.goods_id > 0) {
            this.createSingleGoods()
          } else {
            this.createCartGoods()
          }
        }
      }
      ,
      onGoodsChange (params) {
        // this.goods = params;
        console.log('onGoodsChange', params)
        let self = this
        if (self.goods_id > 0) {
          if (params[0].number == this.number) {
            return
          }
          self.$utils.throttle(
            'changeGoodsNum',
            () => {
              self.$vuex.community_order('number', params[0].number)
              self.changeGoodsNum().then(response => {
                this.$vuex.community_order(
                  'couponList',
                  response.coupon_list
                )
                if (this.useCouponId > 0) {
                  this.addGood(this.useCouponId)
                }
                this.$forceUpdate()
              })
            },
            500,
            false
          )
        }
      }
      ,
      goBuy () {
        if (this.paying) {
          this.$toast.text('正在支付中', {duration: 1000})
          return
        }
        this.paying = true
        let self = this
        if (self.hasRSQInfo && !self.isBuyRSQ) {
          self.$dialog({
            title: '温馨提示',
            content: self.renshengquan_info.warning_text,
            closeOnClickModal: false, //点击蒙层是否关闭对话框
            closeBtn: false, //显式右上角关闭按钮
            onOkBtn () {
              //人生券不足的时候允许购买
              self.$dialog.closed()
              self.useRSQ = true
            },
            onCancelBtn () {
              //人生券不足的时候拒绝购买
              self.$vuex.community_order('isBuyRSQ', false)
              if (self.goods_id > 0) {
                self.createSingleGoods()
              } else {
                self.createCartGoods()
              }
            },
          })
        } else {
          if (self.goods_id > 0) {
            self.createSingleGoods()
          } else {
            self.createCartGoods()
          }
        }
      }
      ,
      //创建单个商品订单
      createSingleGoods () {
        //单个商品创建订单
        let params = {
          goods_id: this.goods_id, //商品ID
          spec_ids: this.specs, //商品规格，多个规格用","连接
          real_name: this.real_name, //提货人
          remark: this.remark, //备注
          real_mobile: this.mobile, //提货人手机号
          number: this.number, //购买数量
          buy: this.isBuyRSQ ? 1 : 0, //下单时是否同时购买人生券 0：不是 1：是 默认为0
          site_point_id:
            this.order_info.express_type == 1
              ? this.express.site_point.site_point_id
              : 0, //站点id
          address_id:
            this.order_info.express_type == 2
              ? this.express.express.address_id
              : 0, //收货地址id
          coupon_id: this.useCouponId,
          coupon_site_point: this.couponSitePoint,
          pay_type:this.pay_type
        }
        if (this.order_info.express_type == 1 && this.choose_address == 1) {
          if ((this.real_name ? this.real_name : '') == '' || (this.mobile ? this.mobile : '') == '') {
            this.$toast.text('提货人信息不能为空', {
              duration: 1000,
            })
            this.setUser = true
            this.paying = false
            return
          }
          if (!params.site_point_id) {
            this.$toast.text('请选择您的寄存自提点', {
              duration: 1000,
            })
            this.paying = false
            return
          }
        } else if (this.order_info.express_type == 2 && this.choose_address == 1) {
          if (!params.address_id) {
            this.$toast.text('收货地址不能为空', {
              duration: 1000,
            })
            this.paying = false
            return
          }
        }
        this.ajaxCreateSingleGoods(params)
      },
      goToOrderList(){
        this.$platform.wxsdk.wxRoute({
          type: 'redirectTo',
          // type: "navigateTo",
          url: '/order/list/common',
        })
      },
      closeUpSuccess(){
        this.showUpSuccess = false
        this.goToOrderList()
      },
      //创建订单
      async ajaxCreateSingleGoods (params) {
        let self = this
        try {
          // if (this.payInfo != null) {
          //   this.wxpay(this.payInfo)
          //   return
          // }
          let query = await
            this.$api.community.order.createBySingle(params)

          if(params.pay_type == 'money'){
            let show_tuan_up_result = false
            if(typeof query.data.show_tuan_up_result != 'undefined'){
              show_tuan_up_result = query.data.show_tuan_up_result
            }

            this.$dialog({
              title: '温馨提示',
              content: query.message,
              closeOnClickModal: false, //点击蒙层是否关闭对话框
              closeBtn: false, //显式右上角关闭按钮
              onOkBtn () {
                this.$dialog.closed()
                if(query.error == 0){
                  if(show_tuan_up_result == true){
                    self.showUpSuccess = true
                  }else{
                    self.goToOrderList()
                  }
                }

              },
              onCancelBtn () {
                this.$dialog.closed()
              },
            })


          }else{
            this.wxpay(query.data)
          }
        } catch (error) {
          this.paying = false
          //商品订单创建失败
          this.$dialog({
            title: '温馨提示',
            content: error.message,
            closeOnClickModal: true, //点击蒙层是否关闭对话框
            closeBtn: false, //显式右上角关闭按钮
            noCancelBtn: true,
            onOkBtn () {
              //人生券不足的时候允许购买
              this.$dialog.closed()
            },
          })
        }
      }
      ,
      //创建购物车订单
      createCartGoods () {
        let params = {
          cart_ids: this.cart_ids, //购物车id
          type: this.order_info.express_type, //1 自提 2快递
          remark: this.remark, //备注
          real_name: this.real_name, //提货人
          real_mobile: this.mobile, //提货人手机号
          buy: this.isBuyRSQ ? 1 : 0, //下单时是否同时购买人生券 0：不是 1：是 默认为0
          site_point_id:
            this.order_info.express_type == 1
              ? this.express.site_point.site_point_id
              : 0, //站点id
          address_id:
            this.order_info.express_type == 2
              ? this.express.express.address_id
              : 0, //收货地址id
          coupon_id: this.useCouponId,
          coupon_site_point: this.couponSitePoint,
          pay_type:this.pay_type
        }
        //创建购物车订单
        if (this.order_info.express_type == 1) {
          if ((this.real_name ? this.real_name : '') == '' || (this.mobile ? this.mobile : '') == '') {
            this.$toast.text('提货人信息不能为空', {
              duration: 1000,
            })
            this.paying = false
            return
          }
          if (!params.site_point_id) {
            this.$toast.text('请选择您的寄存自提点', {
              duration: 1000,
            })
            this.paying = false
            return
          }
        } else {
          if (!params.address_id) {
            this.$toast.text('收货地址不能为空', {
              duration: 1000,
            })
            this.paying = false
            return
          }
        }

        this.ajaxCreateCartGoods(params)
      }
      ,
      //创建购物车订单
      async ajaxCreateCartGoods(params) {
       let self = this
        try {
          let query = await
              this.$api.community.order.createByCart(params)
          if(params.pay_type == 'money'){

            this.$dialog({
              title: '温馨提示',
              content: query.message,
              closeOnClickModal: false, //点击蒙层是否关闭对话框
              closeBtn: false, //显式右上角关闭按钮
              onOkBtn () {
                this.$dialog.closed()
                if(query.error == 0){
                  self.goToOrderList()
                }

              },
              onCancelBtn () {
                this.$dialog.closed()
              },
            })


          }else{
            if (this.payInfo != null) {
              this.wxpay(this.payInfo)
              return
            }
            
              this.wxpay(query.data)
            }
        } catch (error) {
          this.paying = false
          //购物车订单创建失败
          this.$dialog({
            title: '温馨提示',
            content: error.message || error,
            closeOnClickModal: true, //点击蒙层是否关闭对话框
            closeBtn: false, //显式右上角关闭按钮
            noCancelBtn: true,
            onOkBtn () {
              //人生券不足的时候允许购买
              this.$dialog.closed()
            },
          })
        }
      }
      ,
      wxpay (payInfo) {
        this.paying = false
        this.payInfo = {
          ...payInfo,
          remark: this.remark, //备注
          goods_id: this.goods_id, //商品id
          specs: this.specs, //规格
          number: this.number, //购买数量
          cart_ids: this.cart_ids, //购物车id
          type: this.order_info.express_type, //1 自提 2快递 默认自提
          express: this.express, //用户信息
          goods_plan:this.goods_plan
        }
        let options = encodeURIComponent(JSON.stringify(this.payInfo))
        this.$platform.wxsdk.wxRoute({
          type: 'redirectTo',
          // type: "navigateTo",
          url: '/web/money/pay?query=' + options,
        })
      }
      ,
      //修改收货信息
      updataAddress () {
        //更新收货地址
        if (this.$platform.wxsdk.isWechat()) {
          let query = {
            ...this.payInfo,
            remark: this.remark, //备注
            goods_id: this.goods_id, //商品id
            specs: this.specs, //规格
            num: this.number, //购买数量
            cart_ids: this.cart_ids, //购物车id
            type: this.order_info.express_type, //1 自提 2快递 默认自提
            express: this.express || {}, //用户信息
          }
          query = encodeURIComponent(JSON.stringify(query))
          this.$platform.wxsdk.wxRoute({
            type: 'redirectTo',
            url: '/web/address/list?backType=1&query=' + query,
          })
        }
      }
      ,
      //跳转到自提点页面
      updataSite_point () {
        //修改提货地点
        let query = {
          site_point_id: this.express.site_point.site_point_id,
          mapType: 1,
          orderAmount:this.order_info.real_pay_amount
        }
        let goods = JSON.parse(JSON.stringify(this.goods))

        if (goods.length == 1) {
          query.goods_id = goods[0].goods_id
          query.spec_ids = goods[0].spec_ids
          query.number = goods[0].number
        } else {
          //尝试判断商品中去除兑换的商品后是否只剩一个商品
          let goodsNumber = 0
          let realGoods = []
          goods.forEach(item => {

            if ((item?.is_coupon_goods || 0) == 0) {
              goodsNumber++
              realGoods.push(item)
            }
          })
          if (goodsNumber == 1) {
            query.goods_id = realGoods[0].goods_id
            query.spec_ids = realGoods[0].spec_ids
            query.number = realGoods[0].number
          }
        }

        this.$router.push({
          path: 'map',
          query: query,
        })
      }
      ,
      viewSitePoint () {
        //修改提货地点
        let query = {
          site_point_id: this?.express?.site_point?.site_point_id || 0,
          mapType: 1,
          couponSelectSitePoint: 1,
          orderAmount:this.order_info.real_pay_amount
        }
        let goods = this.goods
        if (goods.length == 1) {
          query.goods_id = goods[0].goods_id
        }
        this.$router.push({
          path: 'map',
          query: query,
        })
      },
      //编辑备注
      onRemark (e) {
        if (e.length > 50) {
          this.$toast.text('备注最多50个字符', {duration: 1500})
          return
        }
        this.$vuex.community_order('remark', e)
      }
      ,
      chooseCoupon () {
        this.$refs.couponChoose.show()
      },
      chooseCouponId (id) {
        this.$vuex.community_order(
          'useCouponId',
          id
        )
        if (id == 0) {
          this.$vuex.community_order(
            'couponGoods',
            []
          )
        }
        this.$refs.couponChoose.hide()
      },
      addGood (coupon_id) {
        this.removeCouponGoods()
        this.couponList.forEach(item => {
          if (item.coupon_id == coupon_id) {
            if (item.type == 1) {
              //如果是商品优惠券
              $community.goods.getInfo({id: item.type_value}).then(response => {
                response = response.data
                console.log('goods_info', response)
                //配送类型 1：自提点 2：快递
                let mailType = response.mail_type
                let couponGoods = {
                  cannot_return_reason: '',
                  cart_id: 0,
                  cover: response.cover,
                  deduct_text: '',
                  goods_id: response.id,
                  market_price: response.market_price,
                  number: 1,
                  product_price: response.product_price,
                  spec_ids: '',
                  spec_title: '默认规格',
                  title: response.title,
                  total_market_price: 0,
                  total_product_price: response.product_price,
                  is_coupon_goods: 1
                }

                if (this.order_info.express_type == 1 && mailType == 1) {
                  //如果是自提点取货的商品
                  let goods = this.goods
                  goods.push(couponGoods)
                } else if (this.order_info.express_type == 2 && mailType == 1) {
                  this.$vuex.community_order(
                    'couponGoods',
                    [couponGoods]
                  )
                }
              })
            }
          }
        })
      },
      removeCouponGoods () {
        this.goods.forEach((item, index) => {
          if (typeof item.is_coupon_goods != 'undefined' && item.is_coupon_goods == 1) {
            this.goods.splice(index, 1)
          }
        })
        this.$vuex.community_order(
          'goods',
          this.goods
        )
      },
      chooseWechat(){
        this.pay_type = 'wechat'
      },
      chooseMoney(){
        this.pay_type = 'money'
        this.getUserMoney()
      },
      getUserMoney(){
        $community.users.getUserMoney().then(response => {
          this.userMoney = response.data
        })
      }
    }
    ,
  }
</script>

<style scoped lang="scss">
    #orderConfrim {
        position: relative;
        display: inline-block;
        width: 100%;
        min-height: 100%;
        background: #f6f6f6;
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
    }

    .choose-payment{
      .payment-title{
        font-weight: bold;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
      .payment-type{
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        .payment-type-item{
          flex: 1;
          display: flex;
          justify-content: flex-end;
          font-size: 12px;

          .payment-type-item-span{
            margin-left: 5px;
            margin-top: 1px;
          }
        }

        .payment-type-text{
          flex: 1;
          display: flex;
          justify-content: flex-end;
          font-size: 10px;
          margin-top: 3px;
        }
      }
    }



    @include b(orderConfrim) {
        @include e(foot) {
            z-index: 222;
            position: fixed;
            bottom: 0;
        }
        @include e(placeholder) {
            flex-shrink: 0;
            box-sizing: border-box;
            padding: 33px;
        }
    }

    @include b(card) {
        box-sizing: border-box;
        background: #fff;
        border-radius: 10px;
        @include m(p20) {
            padding: 20px;
        }
    }

    @include b(cell) {
        height: 44px !important;
        &:not(:last-of-type) {
            border-bottom: 1px solid #ebebeb;
        }
        @include e(content) {
            box-sizing: border-box;
            padding-left: 12px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
        }
    }

    @include b(description) {
        width: 250px;
        align-self: flex-end;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #939393;
    }

    /deep/ .remark input {
        width: 100%;
        padding-right: 0;
        border: none;
        outline: none;
        text-align: right;
    }

    .coupon-text-container {
        display: flex;
        justify-content: flex-end;

        .coupon-text {
            background: #F46A17;
            font-size: 12px;
            padding: 3px 10px;
            color: #fff;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;

        }
    }
</style>
