<template>
    <div class="addressV2">
        <cellV2
                :type="10"
                leftText="提货人："
                rightText="修改"
                :rightIcon="require('@/static/image/icons/arrowRight-orange.png')"
                @onRight="updataSite_person"
                @onLeft="updataSite_person"
                @cellClick="updataSite_person"
        >
            <div class="addressV2_content">
                <span>{{ info.real_name }}</span>
                <span>{{ info.mobile }}</span>
            </div>
        </cellV2>
        <div class="box-m-t-14 box-m-b-18 addressV2_split"></div>
        <cellV2
                :type="10"
                leftText="自提点："
                rightText="切换"
                :rightIcon="require('@/static/image/icons/arrowRight-orange.png')"
                @onRight="updataSite_point"
        >
            <div class="addressV2_content">{{ info.site_name }}</div>
        </cellV2>
        <div class="addressV2_info box-m-t-8">
            {{ info.address }}
        </div>
    </div>
</template>

<script>
  import cellV2 from '@T/community/cell/cellV2.vue'

  export default {
    name: 'addressV2',
    components: {
      cellV2,
    },
    props: {
      info: {
        type: Object,
        default () {
          return {
            real_name: '', //提货人名称
            mobile: '', //提货人手机号码
            site_point_id: 0, //自提点ID
            site_name: '', //自提点名称
            address: '', //自提点详细地址
          }
        },
      },
    },
    data () {
      return {}
    },

    mounted () {},

    methods: {
      updataSite_person () {
        //修改提货人
        this.$emit('updataSite_person')
      },
      updataSite_point () {
        //修改提货地点
        this.$emit('updataSite_point')
      },
    },
  }
</script>

<style lang="scss" scoped>
    @include b(addressV2) {
        box-sizing: border-box;
        background: #ffffff;
        border-radius: 10px;
        width: 100%;
        padding: 16px 15px 16px 20px;
        @include e(content) {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            & > span:first-of-type {
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
            }
            & > span:last-of-type {
                margin-left: 7px;
                color: #939393;
            }
        }
        @include e(split) {
            width: 100%;
            height: 1px;
            background-color: #ebebeb;
        }
        @include e(info) {
            box-sizing: border-box;
            padding-left: 58px;
            padding-right: 2px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #939393;
        }
    }
</style>
